import { createSlice } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const initialState = {
  isLoading: false,
  createdCustomerId: cookies.get("setCustomerId") || "",
  createdCustomerError: [],
  createdAddressId: localStorage.getItem("addressIdReceivedFromState") || "",
  getAddressDataState: [],
  createAddress: [],
  pricesDetailsState: [],
  pricesDetailsProductState: "",
  countryCodeState: localStorage.getItem("countryCodeFromState") || "",
  subscriptionDataState: [],
  subscriptionDomainDataState: [],
  subscriptionDataIdState: null,
  subscriptionDomainDataIdState: null,
  subscriptionDomainErrorState: null,
  transactionDataState: "",
  epochSecondState: "",
  changePaymentMethodState: [],
  updateAddressDataState: [],
  getCustromerFromOrganizationIdState: [],
  getDomainDetailsState: [],
  getDomainPricingState: [],
  getNameServerState: [],
  createtNameServerState: [],
  getUserDomainState: [],
  getDNSHostState: [],
  setDNSHostState:[],
  getContactsState: [],
  getSubIdFromSubIdFunctionState: [],
  getLatestDeploymentByProjectIdState: [],
  generateDeploymentState:[],
  deploymentIdState:[],
};

const paymentFlowSlice = createSlice({
  name: "paymentFlow",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getCustomerId(state, action) {
      state.createdCustomerId =
        action.payload === undefined ? "" : action.payload;
    },
    getCustomerError(state, action) {
      state.createdCustomerError =
        action.payload === undefined ? [] : action.payload;
    },
    getAddressId(state, action) {
      state.createdAddressId =
        action.payload === undefined ? "" : action.payload;
    },
    getAddressData(state, action) {
      state.getAddressDataState =
        action.payload === undefined ? [] : action.payload;
    },
    getPricesDetailsAction(state, action) {
      state.pricesDetailsState =
        action.payload === undefined ? [] : action.payload;
      state.pricesDetailsProductState =
        action.payload.product_name === undefined
          ? ""
          : action.payload.product_name;
    },
    getCountryCodeApiAction(state, action) {
      state.countryCodeState =
        action.payload === undefined ? "" : action.payload;
    },
    getSubscriptionDataAction(state, action) {
      state.subscriptionDataState =
        action.payload === undefined ? [] : action.payload;
      state.subscriptionDataIdState =
        action.payload.subscription_id === undefined
          ? ""
          : action.payload.subscription_id;
    },
    getDomainSubscriptionDataAction(state, action) {
      state.subscriptionDomainDataState =
        action.payload === undefined ? [] : action.payload;
      state.subscriptionDomainDataIdState =
        action.payload.subscription_id === undefined
          ? ""
          : action.payload.subscription_id;
    },
    getDomainErrorAction(state, action) {
      state.subscriptionDomainErrorState =
        action.payload === undefined ? "" : action.payload;
    },
    getTransactionDetails(state, action) {
      state.transactionDataState =
        action.payload === undefined ? "" : action.payload;
    },
    getEpochSecondAction(state, action) {
      state.epochSecondState =
        action.payload === undefined ? "" : action.payload;
    },
    changePaymentMethodAction(state, action) {
      state.changePaymentMethodState =
        action.payload === undefined ? [] : action.payload;
    },
    updateAddressAction(state, action) {
      state.updateAddressDataState =
        action.payload === undefined ? [] : action.payload;
    },
    getCustromerFromOrganizationIdAction(state, action) {
      state.getCustromerFromOrganizationIdState =
        action.payload === undefined ? [] : action.payload;
    },
    getDomainDetailsAction(state, action) {
      state.getDomainDetailsState =
        action.payload === undefined ? [] : action.payload;
    },
    getDomainPricingAction(state, action) {
      state.getDomainPricingState =
        action.payload === undefined ? [] : action.payload;
    },
    getNameServerAction(state, action) {
      state.getNameServerState =
        action.payload === undefined ? [] : action.payload;
    },
    createNameServerAction(state, action) {
      state.createtNameServerState =
        action.payload === undefined ? [] : action.payload;
    },

    getUserDomainAction(state, action) {
      state.getUserDomainState =
        action.payload === undefined ? [] : action.payload;
    },
    getDNSHostAction(state, action) {
      state.getDNSHostState =
        action.payload === undefined ? [] : action.payload;
    },
    setDNSHostAction(state, action) {
      state.setDNSHostState =
        action.payload === undefined ? [] : action.payload;
    },
    getContactsAction(state, action) {
      state.getContactsState =
        action.payload === undefined ? [] : action.payload;
    },
    getSubIdFromSubIdFunctionAction(state, action) {
      state.getSubIdFromSubIdFunctionState =
        action.payload === undefined ? [] : action.payload;
    },
    getLatestDeploymentByProjectIdAction(state, action) {
      state.getLatestDeploymentByProjectIdState =
        action.payload === undefined ? [] : action.payload;
    },
    generateDeploymentAction(state, action) {
      state.generateDeploymentState =
        action.payload === undefined ? [] : action.payload;
    },

    deploymentByIdAction(state, action) {
      state.deploymentIdState =
        action.payload === undefined ? [] : action.payload;
    },
  

  },
});

export default paymentFlowSlice.reducer;
export const {
  getCustomerId,
  getCustomerError,
  getAddressId,
  getAddressData,
  getPricesDetailsAction,
  getCountryCodeApiAction,
  getSubscriptionDataAction,
  getDomainSubscriptionDataAction,
  getDomainErrorAction,
  getTransactionDetails,
  getEpochSecondAction,
  changePaymentMethodAction,
  updateAddressAction,
  getCustromerFromOrganizationIdAction,
  getDomainDetailsAction,
  getDomainPricingAction,
  getNameServerAction,
  createNameServerAction,
  getUserDomainAction,
  getContactsAction,
  getDNSHostAction,
  setDNSHostAction,
  getSubIdFromSubIdFunctionAction,
  getLatestDeploymentByProjectIdAction,
  generateDeploymentAction,
  deploymentByIdAction,
} = paymentFlowSlice.actions;
