import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dmnList: [],
  dmnScreen: false,
  dmnById: null,
  isLoadingDmn: false,
};

const dmn = createSlice({
  name: 'dmn',
  initialState,
  reducers: {
    getDmnScreen(state, action) {
      state.dmnScreen = action.payload;
    },
    getDmnList(state, action) {
      state.dmnList = action.payload;
    },
    getDmnById(state, action) {
      state.dmnById = action.payload;
    },
    dmnLoading(state, action) {
      state.isLoadingDmn = action.payload;
    },
  },
});

export default dmn.reducer;
export const { getDmnScreen, getDmnList, getDmnById, dmnLoading } = dmn.actions;
