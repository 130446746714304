export const refreshTokenQuery = {
  query: `mutation ($refreshToken: String!){
    refreshToken(
        refreshToken: $refreshToken 
       ) {
        token 
        expiresIn 
        refreshExpiresIn 
        tokenType 
        refreshToken 
        authorities
        pkgAuthorities
        attributes
   }
}`,
};

export const loginQuery = {
  query: `mutation ($username: String!, $password: String!, $sso: String, $sig: String) {login(username: $username password: $password sso: $sso sig:$sig) {
    token 
    expiresIn 
    refreshExpiresIn 
    tokenType 
    refreshToken 
    authorities
    redirectUrl
    pkgAuthorities
    attributes
  }
}`,
};

export const ssoRedirectUrlQuery = {
  query: `mutation {
    getSsoRedirectUrl(
        sso:"",
        sig:"",
        id:""
      ) 
  }`,
};

export const registerQuery = {
  query: `mutation ($request: RegisterRequest!) {
      register(
          request: $request
        ){
            id
        }
    }`,
};
export const createCustomerQuery = {
  query: `mutation ($request: CustomerCreateParams!) {
  createCustomer(
      request: $request
    ){
        id
        customerId
        customerName
    }
}`,
};
export const getCustomerQuery = {
  query: `query  {
  getCustomer{
        id
        customerId
        customerName
    }
}`,
};

export const checkDomainsQuery = (domainName) => ({
  query: `
    query CheckDomains {
      checkDomains(domains: "${domainName}") {
      
        domain
        available
        description
        isPremium
        premiumRegistrationPrice
        premiumRenewalPrice
        premiumRestorePrice
        iCannFee
        eapFee
      }
    }
  `,
});

export const getNameCheapPricingQuery = (domainExtension) => ({
  query: `
    query GetTldPricing {
    getTldPricing(tld: "${domainExtension}") {
        name
        minRegisterYears
        maxRegisterYears
        minRenewYears
        maxRenewYears
        renewalMinDays
        renewalMaxDays
        isEppRequired
        pricing {
            type
            prices {
                price
                additionalCost
                duration
            }
        }
    }
}


  `,
});

export const getTldsQuery = {
  query: `query GetNamecheapTlds {
    getNamecheapTlds {
        name
        minRegisterYears
        maxRegisterYears
        minRenewYears
        maxRenewYears
        renewalMinDays
        renewalMaxDays
        isEppRequired
        type
        subType
        isSupportsIDN
        tldState
        tld
    }
}

`,
};
export const createProfileQuery = {
  query: `mutation ($profile: ProfileCreateRequest!) {
  createProfile(
      request: $profile
    ){
        id
    }
}`,
};
export const getProfileQuery = {
  query: `query {
  profiles {
   id
   name
   permissionIds
  }
}`,
};
export const sendotpQuery = (email) => ({
  query: `
        query {
          userEmailExists(email: "${email}")
        }
      `,
});

export const generateOtpQuery = (email) => ({
  query: `
        mutation {
          generateOtp(email: "${email}")
        }
      `,
});

export const logoutQuery = {
  query: `mutation ($tokenId: ID!) {
    logoutUserByTokenId(
        tokenId : $tokenId
       ) {
           id
       }
}`,
};

export const emailExistsQuery = {
  query: `query ($email: String!) {
    userEmailExists(
        email: $email
      )
  }`,
};

export const completeResetPasswordQuery = {
  query: `mutation ($email: String!, $otp: String!, $newPassword: String!) {
    completeResetPassword(
        email: $email 
        otp: $otp 
        newPassword: $newPassword
       ) {
       id
   }
  }`,
};

export const changePasswordQuery = {
  query: `mutation ($username: String!, $oldPassword: String!, $newPassword: String!) {
    changePassword(
        username: $username 
        oldPassword: $oldPassword
        newPassword: $newPassword
      ) {
      id
  }
}`,
};

export const createTeamMemberQuery = {
  query: `mutation ($request: UserCreateRequest!) {
    createUser(
        request: $request
      ){
          id
      }
  }
  `,
};

export const getAlertsQuerry = (resolve) => ({
  query: `
  query GetAlerts {
    getAlerts(resolved: ${resolve}) {
        errorMessage
        resolvedBy
        resolved
        subscriptionId
        organizationId
        createdTime
        updatedTime
        uuid
    }
}

`,
});
export const resolveAlertQuerry = () => ({
  query: `
  mutation ResolveAlert {
    resolveAlert
}`,
});

export const getUserDomainsQuerry = () => ({
  query: `
  query GetUserDomains {
    getUserDomains {
        domainName
        registered
        chargeAmount
        domainId
        orderId
        transactionId
        createdDate
        expiredDate
        years
        whoisGuardId
        whoisGuardExpiredDate
        lastPerformedAction
        whoisGuardEnabled
        forwardedToEmail
        subscriptionId
        registrarLockStatus
        autoRenew
        isPremium
    }
}
`,
});

export const updateTeamMemberQuery = (memberData) => ({
  query: `mutation {
    updateUser(request: 
      {
            id: "${memberData.id}",
             firstName: "${memberData.firstName}",
             lastName: "${memberData.lastName}",
             email: "${memberData.email}",
             mobile: "${memberData.mobile}",
             profileId: "${memberData.profileId}",
      })
    {
        id
    }
  }
  `,
});

export const getTeamMemberDetailsQuery = (id) => ({
  query: `query {
    userById(id: "${id}") {
      id
    username
    firstName
    lastName
    email
    mobile
    profile {
        id
        name
    } 
 }
}
  `,
});

export const socketQuery = {
  query: ` mutation  {
    authenticateWebSocket
}
  `,
};
export const createDomainQuery = (
  updatedFormData,
  selectedCountryNameCode,
  selectedCountry,
  subscription_id,
  checkedWhoIsGuard,
  formattedExtendedAttributes
) => {
  console.log(
    "crreate DOmian FornmDtata insode querry mutation",
    updatedFormData
  );
  console.log(
    "crreate DOmian FornmDtata insode querry mutation domain name",
    updatedFormData.DomainName
  );
  console.log("222222 check who is insode querry mutation", checkedWhoIsGuard);
  console.log(
    " 88888sub id in crreate DOmian acgtion  in QWUeeerrrry ",
    subscription_id
  );
  console.log(
    "++++ extended attributescrreate DOmian acgtion  in QWUeeerrrry ",
    formattedExtendedAttributes
  );

  console.log(
    "++++++ phone insode querry mutation",
    selectedCountry + "." + updatedFormData.phone
  );

  return {
    query: ` mutation CreateDomain {
    
      createDomain(
          request: {
              type: {
                  DomainName: "${updatedFormData.DomainName}"
                  Years: ${updatedFormData.Years}
                  PromotionCode: null 
                  RegistrationOrganizationName: null
                  RegistrantJobTitle: null
                  RegistrantFirstName: "${updatedFormData.RegistrantFirstName}"
                  RegistrantLastName: "${updatedFormData.RegistrantLastName}"
                  RegistrantAddress1: "${updatedFormData.RegistrantAddress1}"
                  RegistrantCity: "${updatedFormData.RegistrantCity}"
                  RegistrantStateProvince: "${updatedFormData.RegistrantStateProvince}"
                  RegistrantStateProvinceChoice: null
                  RegistrantPostalCode: "${updatedFormData.RegistrantPostalCode}"
                  RegistrantCountry: "${selectedCountryNameCode}"
                  RegistrantPhone: "${selectedCountry + "." + updatedFormData.phone}"
                  RegistrantPhoneExt: null
                  RegistrantFax: null
                  RegistrantEmailAddress: "${updatedFormData.RegistrantEmailAddress}"
                  TechOrganizationName: null
                  TechJobTitle: null
                  TechFirstName: "${updatedFormData.RegistrantFirstName}"
                  TechLastName: "${updatedFormData.RegistrantLastName}"
                  TechAddress1: "${updatedFormData.RegistrantAddress1}"
                  TechCity: "${updatedFormData.RegistrantCity}"
                  TechStateProvince: "${updatedFormData.RegistrantStateProvince}"
                  TechStateProvinceChoice: null
                  TechPostalCode: "${updatedFormData.RegistrantPostalCode}"
                  TechCountry: "${selectedCountryNameCode}"
                  TechPhone: "${selectedCountry + "." + updatedFormData.phone}"
                  TechPhoneExt: null
                  TechFax: null
                  TechEmailAddress: "${updatedFormData.RegistrantEmailAddress}"
                  AdminOrganizationName: null
                  AdminJobTitle:null
                  AdminFirstName:"${updatedFormData.RegistrantFirstName}"
                  AdminLastName: "${updatedFormData.RegistrantLastName}"
                  AdminAddress1: "${updatedFormData.RegistrantAddress1}"
                  AdminAddress2: null
                  AdminCity: "${updatedFormData.RegistrantCity}"
                  AdminStateProvince: "${updatedFormData.RegistrantStateProvince}"
                  AdminStateProvinceChoice: null
                  AdminPostalCode: "${updatedFormData.RegistrantPostalCode}"
                  AdminCountry: "${selectedCountryNameCode}"
                  AdminPhone: "${selectedCountry + "." + updatedFormData.phone}"
                  AdminPhoneExt: null
                  AdminFax: null
                  AdminEmailAddress: "${updatedFormData.RegistrantEmailAddress}"
                  AuxBillingOrganizationName:null
                  AuxBillingJobTitle: null
                  AuxBillingFirstName:"${updatedFormData.RegistrantFirstName}"
                  AuxBillingLastName: "${updatedFormData.RegistrantLastName}"
                  AuxBillingAddress1: "${updatedFormData.RegistrantAddress1}"
                  AuxBillingAddress2: null
                  AuxBillingCity: "${updatedFormData.RegistrantCity}"
                  AuxBillingStateProvince: "${updatedFormData.RegistrantStateProvince}"
                  AuxBillingStateProvinceChoice: null
                  AuxBillingPostalCode: "${updatedFormData.RegistrantPostalCode}"
                  AuxBillingCountry: "${selectedCountryNameCode}"
                  AuxBillingPhone: "${selectedCountry + "." + updatedFormData.phone}"
                  AuxBillingPhoneExt: null
                  AuxBillingFax: null
                  AuxBillingEmailAddress: "${updatedFormData.RegistrantEmailAddress}"
                  BillingFirstName: "${updatedFormData.RegistrantFirstName}"
                  BillingLastName: "${updatedFormData.RegistrantLastName}"
                  BillingAddress1: "${updatedFormData.RegistrantAddress1}"
                  BillingAddress2: null
                  BillingCity: "${updatedFormData.RegistrantCity}"
                  BillingStateProvince: "${updatedFormData.RegistrantStateProvince}"
                  BillingStateProvinceChoice: null
                  BillingPostalCode: "${updatedFormData.RegistrantPostalCode}"
                  BillingCountry: "${selectedCountryNameCode}"
                  BillingPhone: "${selectedCountry + "." + updatedFormData.phone}"
                  BillingPhoneExt: null
                  BillingFax: null
                  BillingEmailAddress: "${updatedFormData.RegistrantEmailAddress}"
                  IdnCode: null
                  PremiumPrice: null
                  EapFee: null
                  ExtendedAttributes:[${formattedExtendedAttributes}]
                  IsPremiumDomain: null
                   AddFreeWhoisguard: "${checkedWhoIsGuard}"
              WGEnabled: "${checkedWhoIsGuard}"
                  
              }
             
              subscriptionId: "${subscription_id}"
             
              tld: "${updatedFormData.tld}"
              autoRenew: ${updatedFormData.autoRenew}
          }
      ) {
          domainName
        registered
        chargeAmount
        domainId
        orderId
        transactionId
        createdDate
        expiredDate
        years
        whoisGuardId
        whoisGuardExpiredDate
        lastPerformedAction
        whoisGuardEnabled
        forwardedToEmail
        subscriptionId
        registrarLockStatus
        autoRenew
        isPremium
      }
  }
  
    `,
  };
};

export const renewDomainQuerry = (
  subscription_id,
  domainName,
  numberOfYears,
  isPremiumDomain,
  premiumPrice
) => ({
  query: `
  mutation RenewDomain {
    renewDomain(
        request: {
            subscriptionId: "${subscription_id}"
           
            type: {
                DomainName: "${domainName}"
                Years: ${numberOfYears}
                IsPremiumDomain: null
                PremiumPrice: null
            }
        }
    ) {
        domainName
        registered
        chargeAmount
        domainId
        orderId
        transactionId
        createdDate
        expiredDate
        years
        whoisGuardId
        whoisGuardExpiredDate
        lastPerformedAction
        whoisGuardEnabled
        forwardedToEmail
        subscriptionId
        registrarLockStatus
        autoRenew
        isPremium
    }
}`,
});

export const reactivateDomainQuerry = (
  subscription_id,
  organisationId,
  domainName,
  numberOfYears,
  isPremiumDomain,
  premiumPrice
) => ({
  query: `
  mutation ReactivateDomain {
    reactivateDomain(
        request: {
            subscriptionId: "${subscription_id}"
           
            type: {
                domainName: "${domainName}"
                years: "${numberOfYears}"
                isPremiumDomain: "${isPremiumDomain}"
                premiumPrice: "${premiumPrice}"
            }
        }
    ) {
        domainName
        registered
        chargeAmount
        domainId
        orderId
        transactionId
        createdDate
        expiredDate
        years
    }
}`,
});

export const ToggleDomainPrivacyQuerry = (
  forwardedToEmail,
  toggle,
  whoisGuardID
) => ({
  query: `
  mutation ToggleDomainPrivacy {
    toggleDomainPrivacy(
        request: { 
        forwardedToEmail: "${forwardedToEmail}", 
        toggle: ${toggle},
         whoisGuardId: "${whoisGuardID}" 
         }
    ) {
        domainName
        registered
        chargeAmount
        domainId
        orderId
        transactionId
        createdDate
        expiredDate
        years
        whoisGuardId
        whoisGuardExpiredDate
        lastPerformedAction
        whoisGuardEnabled
        forwardedToEmail
    }
}`,
});

export const getDNSHostsQuery = (domainName) => ({
  query: `
      query GetDNSHosts {
    getDNSHosts(domain: "${domainName}") {
        hostId
        name
        mxPref
        ttl
        domain
        address
        type
        friendlyName
        isDDNSEnabled
        isActive
        associatedAppTitle
        uuid
    }
}

    `,
});

export const getNameServersQuery = (domainName) => ({
  query: `
      query GetNameServers {
    getNameServers(domain: "${domainName}") {
        domain
        nameServer
        ip
        uuid
    }
}

    `,
});

export const setDefaultDNSQuerry = (sld, tld) => ({
  query: `
    mutation SetDefaultDNS {
    setDefaultDNS(sld: "${sld}", tld: "${tld}") {
       domainName
        isUsingOurDns
        isUsingFreeDns
        isPremiumDns
        createdAt
        updatedAt
    }
}
`,
});

export const setCustomDNSQuerry = (sld, tld, nameservers) => ({
  query: `
   mutation SetCustomDNS {
    setCustomDNS(sld: "${sld}", tld: "${tld}", nameservers: "${nameservers}") {
       domainName
        isUsingOurDns
        isUsingFreeDns
        isPremiumDns
        createdAt
        updatedAt
    }
}

`,
});

export const createNameServerQuerry = (sld, tld, nameservers, ip) => ({
  query: `
   mutation CreateNameServer {
    createNameServer(sld: "${sld}", tld: "${tld}", nameserver: "${nameservers}", ip: "${ip}") {
        domain
        nameServer
        ip
        uuid
    }
}


`,
});

export const updateNameServerQuerry = (sld, tld, nameservers, oldIp, ip) => ({
  query: `
   mutation UpdateNameServer {
    updateNameServer(sld: "${sld}", tld: "${tld}", nameserver: "${nameservers}", oldIp: "${oldIp}", ip: "${ip}") {
        domain
        nameServer
        ip
        uuid
    }
}

`,
});

export const deleteNameServerQuerry = (sld, tld, nameserver) => ({
  query: `
  mutation DeleteNameServer {
    deleteNameServer(sld: "${sld}", tld: "${tld}", nameserver: "${nameserver}")
}

`,
});

export const setHostsQuerry = (sld, tld, detailList) => ({
  query: `
  mutation SetHosts {
    setHosts(sld: "${sld}", tld: "${tld}",  detailList: ${detailList}) {
        hostId
        name
        mxPref
        ttl
        domain
        type
        friendlyName
        isDDNSEnabled
        isActive
        associatedAppTitle
        uuid
    }
}


`,
});
export const getDNSHostsQuerry = (domainName) => ({
  query: `
  query GetDNSHosts {
    getDNSHosts(domain: "${domainName}") {
        hostId
        name
        mxPref
        ttl
        domain
        type
        friendlyName
        isDDNSEnabled
        isActive
        associatedAppTitle
        uuid
    }
}


`,
});

export const setRegistrarStatusLockQuerry = (domainName, lock) => ({
  query: `
 mutation SetRegistrarStatusLock {
    setRegistrarStatusLock(domain: "${domainName}", lock: ${lock}) {
        domainName
        registered
        chargeAmount
        domainId
        orderId
        transactionId
        createdDate
        expiredDate
        years
        whoisGuardId
        whoisGuardExpiredDate
        lastPerformedAction
        whoisGuardEnabled
        forwardedToEmail
        subscriptionId
        registrarLockStatus
        autoRenew
    }
}


`,
});

export const toggleAutoRenewQuerry = (domainName, autoRenew) => ({
  query: `
 mutation ToggleAutoRenew {
    toggleAutoRenew(domain: "${domainName}", autoRenew: ${autoRenew}) {
        domainName
        registered
        chargeAmount
        domainId
        orderId
        transactionId
        createdDate
        expiredDate
        years
        whoisGuardId
        whoisGuardExpiredDate
        lastPerformedAction
        whoisGuardEnabled
        forwardedToEmail
        subscriptionId
        registrarLockStatus
        autoRenew
        isPremium
    }
}


`,
});

export const getContactsQuerry = (domainName) => ({
  query: `
 query GetContacts {
    getContacts(domain: "${domainName}") {
        registrant {
            organisationName
            jobTitle
            firstName
            lastName
            address1
            address2
            city
            stateProvince
            stateProvinceChoice
            postalCode
            country
            phone
            fax
            emailAddress
            phoneExt
        }
        admin {
            organisationName
            jobTitle
            firstName
            lastName
            address1
            address2
            city
            stateProvince
            stateProvinceChoice
            postalCode
            country
            phone
            fax
            emailAddress
            phoneExt
        }
        tech {
            organisationName
            jobTitle
            firstName
            lastName
            address1
            address2
            city
            stateProvince
            stateProvinceChoice
            postalCode
            country
            phone
            fax
            emailAddress
            phoneExt
        }
    }
}
`,
});

export const setContactsQuerry = (domainName, editedContactData) => ({
  query: `
 mutation SetContacts {
    setContacts(
        domain: "${domainName}"
        request: {
            RegistrationOrganizationName: null
            RegistrantJobTitle: null
            RegistrantFirstName: "${editedContactData.firstName}"
            RegistrantLastName: "${editedContactData.lastName}"
            RegistrantAddress1: "${editedContactData.address1}"
            RegistrantCity: "${editedContactData.city}"
            RegistrantStateProvince: "${editedContactData.stateProvince}"
            RegistrantStateProvinceChoice: null
            RegistrantPostalCode: "${editedContactData.postalCode}"
            RegistrantCountry: "${editedContactData.country}"
            RegistrantPhone: "${editedContactData.phone}"
            RegistrantPhoneExt: null
            RegistrantFax: null
            RegistrantEmailAddress: "${editedContactData.emailAddress}"
            TechOrganizationName: null
            TechJobTitle: null
            TechFirstName: "${editedContactData.firstName}"
            TechLastName: "${editedContactData.lastName}"
            TechAddress1: "${editedContactData.address1}"
            TechCity: "${editedContactData.city}"
            TechStateProvinceChoice: null
            TechStateProvince: "${editedContactData.stateProvince}"
            TechPostalCode: "${editedContactData.postalCode}"
            TechCountry: "${editedContactData.country}"
            TechPhone: "${editedContactData.phone}"
            TechPhoneExt: null
            TechFax: null
            TechEmailAddress: "${editedContactData.emailAddress}"
            AdminOrganizationName: null
            AdminJobTitle: null
            AdminFirstName: "${editedContactData.firstName}"
            AdminLastName: "${editedContactData.lastName}"
            AdminAddress1: "${editedContactData.address1}"
            AdminAddress2: null
            AdminCity: "${editedContactData.city}"
            AdminStateProvince: "${editedContactData.stateProvince}"
            AdminStateProvinceChoice: null
            AdminPostalCode: "${editedContactData.postalCode}"
            AdminCountry: "${editedContactData.country}"
            AdminPhone: "${editedContactData.phone}"
            AdminPhoneExt: null
            AdminFax: null
            AuxBillingOrganizationName: null
            AdminEmailAddress: "${editedContactData.emailAddress}"
            AuxBillingJobTitle: null
            AuxBillingFirstName: "${editedContactData.firstName}"
            AuxBillingLastName: "${editedContactData.lastName}"
            AuxBillingAddress1: "${editedContactData.address1}"
            AuxBillingAddress2: null
            AuxBillingCity: "${editedContactData.city}"
            AuxBillingStateProvince: "${editedContactData.stateProvince}"
            AuxBillingStateProvinceChoice: null
            AuxBillingPostalCode: "${editedContactData.postalCode}"
            AuxBillingCountry: "${editedContactData.country}"
            AuxBillingPhone: "${editedContactData.phone}"
            AuxBillingPhoneExt: null
            AuxBillingFax: null
            AuxBillingEmailAddress: "${editedContactData.emailAddress}"
        }
    ) {
        admin {
            organisationName
            jobTitle
            firstName
            lastName
            address1
            address2
            city
            stateProvince
            stateProvinceChoice
            postalCode
            country
            phone
            fax
            emailAddress
            phoneExt
        }
        tech {
            organisationName
            jobTitle
            firstName
            lastName
            address1
            address2
            city
            stateProvince
            stateProvinceChoice
            postalCode
            country
            phone
            fax
            emailAddress
            phoneExt
        }
        registrant {
            organisationName
            jobTitle
            firstName
            lastName
            address1
            address2
            city
            stateProvince
            stateProvinceChoice
            postalCode
            country
            phone
            fax
            emailAddress
            phoneExt
        }
    }
}

`,
});

export const generateDeployQuerry = (projectUuid, domain, subDomain, type) => ({
  query: `
   mutation {
  deploy(
      request: {
         projectId:"${projectUuid}",
          subDomain:"${subDomain}",
         domain:"${domain}",
         type:"${type}"
        }
    ){
        id
    }
}
`,
});

export const latestDeploymentByProjectIdQuerry = (projectUuid) => ({
  query: `
    query { 
     latestDeploymentByProjectId(projectId: "${projectUuid}") {
id
buildNumber
status
progressPercent
projectId
deployedFor
createdAt
updatedAt
logDetails
     }

}
`,
});
export const deploymentsQuerry = () => ({
  query: `
    query {
  deployments
    {
        id
        buildNumber
        projectId
        active
        status
        progressPercent
        
    }
}
`,
});

export const deploymentByIdQuerry = (generatedId) => ({
  query: `
    query {
  deploymentById(
     id: "${generatedId}"
    ){
        id
        status
        active
        progressPercent
        
        
    }
}
`,
});
