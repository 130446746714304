import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  bpnmList: [],
  bpnmScreen: false,
  bpnmById: null,
  isLoadingBpnm: false,
  hideBpnm: false,
};

const bpnm = createSlice({
  name: 'bpnm',
  initialState,
  reducers: {
    getBpnmScreen(state, action) {
      state.bpnmScreen = action.payload;
    },
    getBpnmList(state, action) {
      state.bpnmList = action.payload;
    },
    getBpnmById(state, action) {
      state.bpnmById = action.payload;
    },
    bpnmLoading(state, action) {
      state.isLoadingBpnm = action.payload;
    },
    hideBpnm(state, action) {
      state.hideBpnm = action.payload;
    },
  },
});

export default bpnm.reducer;
export const { getBpnmScreen, getBpnmList, getBpnmById, bpnmLoading, hideBpnm } = bpnm.actions;
