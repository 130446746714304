import axios from "axios";
import Cookies from "universal-cookie";
import {
  refreshToken,
  logoutService,
  manualUserLogout,
  emailExists,
} from "../Middleware/action";
import ErrorHandler, { errorCodes } from "./errorCodes";
import { toastr } from "react-redux-toastr";

const cookies = new Cookies();
export const handleAPICall = async (state) => {
  const { body, method, endpoint,formData,constent, ...rest } = state;
  const token = cookies.get("token") || null;
  try {
    rest.hasError(false);
    if (rest.startLoading) {
      rest.startLoading();
    }

    const url = `${endpoint}`;
    const config = {
      method,
      headers: {
        Authorization: token ? token : "",
        "Content-Type": constent ||  "application/json",
      },
      data: formData ?  formData :  { ...body } ,
    };

    const response = await axios(url, config);

    if (response) {
      handlingTokenExpire(response, state);
    }

    if (rest.stopLoading) {
      rest.stopLoading();
    }
  } catch (error) {
    if (rest.stopLoading) {
      rest.stopLoading();
    }
    rest?.hasError(true);
    //toastr.error("", "Something went wrong !!");
  }
};

const handlingTokenExpire = (response, state) => {
  const { navigateTo, ...rest } = state;
  let arrErr = response.data.errors;
  if (arrErr != null) {
    if (arrErr.length > 0) {
      rest.hasError(true);
      toastr.error("", arrErr[0]?.message || "Something went wrong please try again" );

      for (let i = 0; i < arrErr.length; i++) {
        const errorCode = arrErr[0].message;
        if (errorCode == errorCodes.not_authorized.code) {
          //Call Refresh Token API
          // if (localStorage.getItem("calledTimes") > 1) {
          //   localStorage.setItem("calledTimes", 0);
          // } else {
            refreshToken(state);
          // }
        } else if (
          errorCode == errorCodes.refreshTokenExpired.code ||
          errorCode == errorCodes.invalid_refresh_token.code ||
          errorCode.includes(errorCodes.InvalidInput.code)
        ) {
          if (errorCode.includes(errorCodes.InvalidInput.code)) {
            if (state.func() == logoutService()) {
              //Manually logout User
              manualUserLogout();
            }
          } else {
            //Call Logout API
            logoutService();
          }
        } else {
          const errDescription = ErrorHandler(errorCode);
          toastr.error("", errDescription);

          //RESET_PASSWORD_REQUIRED
          if (errorCode == errorCodes.reset_password_required.code) {
            //Navigate to Reset Password Screen
            emailExists({ email: state.body.variables.username }, navigateTo);
          }
        }
      }
    }
  } else {
    if (rest.getResponse) {
      rest.getResponse(response);
    }
  }
};
