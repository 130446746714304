export const createProjectMutation = (title, description, categoryId) => ({
  input: {
    project: {
      title,
      description,
      categoryId,
    },
  },
});
export function getProjectMutation(count, pageNumber) {
  const hasPagination = count && pageNumber; // Check if pagination parameters exist

  return {
    variables: {
      condition: {
        isDeleted: 0, // Fetch only records where isDeleted is 0
      },
      orderBy: "NATURAL",
      ...(hasPagination && {
        indexFields: {
          first: count,
          offset: (pageNumber - 1) * count,
        },
      }), // Apply pagination only if count & pageNumber are provided
    },
  };
}

export function getProjectTeamDetailMutation(condition) {
  const response = {
    variables: {
      condition: condition,
      orderBy: "NATURAL",
      indexFields: {},
    },
  };

  return response;
}

export function getProjectUnassignedTeamMutation() {
  const response = {
    variables: {
      condition: {},
      orderBy: "NATURAL",
      indexFields: {
        last: 10,
      },
    },
  };

  return response;
}

export const updateProjectMutation = (dataToUpdate, projectId) => ({
  input: {
    projectPatch: { ...dataToUpdate },
    projectId,
  },
});

export const updateAssignedUsersaMutation = (userObj) => ({
  input: userObj,
});

export const deleteUserMutation = (userId) => ({
  id: userId,
});

export const createProjectUserMutation = (userObj) => ({
  input: userObj,
});
