import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isScreenDataLoading: false,
  screenDataID: null,
  allScreenData: [],
  totalCount:10,
  html: null,
  rootId: null,
  style: null,
};

const allScreenData = createSlice({
  name: "allScreenData",
  initialState,
  reducers: {
    startLoadingScreenData(state) {
      state.isScreenDataLoading = true;
    },
    getAllScreenDatas(state, action) {
      state.allScreenData = action.payload === undefined ? [] : action.payload;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    stopLoadingScreenData(state) {
      state.isScreenDataLoading = false;
    },
    getScreenDataID(state, action) {
   
      state.screenDataID = action.payload || null;
    },
    getPageComponents(state, action) {
      state.html  =  action?.payload?.html || null
      state.style = action?.payload?.style || null;
      state.rootId = action.payload?.rootId || null
    },
    getTotalCount(state,action){
   state.totalCount = action.payload || 10
    }
  },
});

export default allScreenData.reducer;
export const {
  getAllScreenDatas,
  startLoadingScreenData,
  stopLoadingScreenData,
  getScreenDataID,
  getPageComponents,
  getTotalCount,
} = allScreenData.actions;
