import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  progressLoading: false,
};

const progressLoader = createSlice({
  name: 'progressLoader',
  initialState,
  reducers: {
    handleProgressLoading(state, action) {
      state.progressLoading = action.payload;
    },
  },
});

export default progressLoader.reducer;
export const { handleProgressLoading } = progressLoader.actions;
